import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    shopLocationId: Number,
  };

  connect() {
    $("#timeoutModal").dialog({
      autoOpen: false,
      resizable: false,
      width: 700,
    });
    document.addEventListener("mousemove", this.resetIdleTimer.bind(this));
    document.addEventListener("keydown", this.resetIdleTimer.bind(this));
    document.addEventListener("mousedown", this.resetIdleTimer.bind(this));
    this.resetIdleTimer();
  }

  get idleDuration() {
    return 60000; // 60 Seconds
  }

  get responseWaitTime() {
    return 6000; // 6 seconds
  }

  resetIdleTimer() {
    if (this.idleTimer || this.noResponseTimer) {
      clearTimeout(this.idleTimer);
      clearTimeout(this.noResponseTimer);
    }
    const that = this;
    this.idleTimer = setTimeout(() => {
      $("#timeoutModal").dialog("open");
      this.noResponseTimer = setTimeout(() => {
        console.log("setting no response timer");
        if (that.idleTimer) {
          Turbo.visit(
            `/shop_location/${this.shopLocationIdValue}/sommelier/kiosk`
          );
        }
      }, this.responseWaitTime);
    }, this.idleDuration);
  }

  closeModal() {
    $("#timeoutModal").dialog("close");
  }
}
